import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import PreDownload from './pre_download'
import PostDownload from './post_download'
import { langs } from '../../langs'
// SVGs
import WhiteLogo from '../../static/img/hubtype_logo_white.svg'
import TriangleHeader from '../../static/img/download_content/TriangleHeader.svg'
import TriangleHeaderTiny from '../../static/img/download_content/TriangleHeaderTiny.svg'
import TriangleHeaderBgDesktop from '../../static/img/download_content/TriangleHeaderBgDesktop.svg'
import TriangleHeaderBgMobile from '../../static/img/download_content/TriangleHeaderBgMobile.svg'

const navbarColor = '#262626'
const headerBgColor = '#362745'

const BgNavbar = styled.div`
  background-color: ${headerBgColor};
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 52px;
  color: #ffffff;
  @media (max-width: 512px) {
    font-size: 32px;
    line-height: 132.19%;
  }
`

const Navbar = props => {
  return (
    <Flex
      py={[15, 15]}
      px={[15, 165]}
      width={1}
      css={{ background: navbarColor, zIndex: 1 }}
    >
      <a href={`${langs[props.pageContext.lang]}/`}>
        <WhiteLogo />
      </a>
    </Flex>
  )
}

const Content = props => {
  let {
    page: {
      acf: { title },
    },
  } = props
  return (
    <Section
      background={
        <>
          <BgNavbar style={{ height: 1100 }} />
        </>
      }
    >
      <MobileFlex width={1}>
        <TriangleHeaderBgMobile
          style={{ position: 'absolute', zIndex: -1, right: 0, top: -20 }}
        />
      </MobileFlex>
      <Flex px={[15, 15]}>
        <Flex pt={[50, 92]} pb={[50, 80]} width={[1, 1 / 2]}>
          <Title>{title}</Title>
        </Flex>
        <DesktopFlex
          width={1 / 2}
          style={{
            position: 'relative',
          }}
        >
          <TriangleHeaderBgDesktop
            style={{ position: 'absolute', top: -25, left: 0 }}
          />
          <TriangleHeader
            style={{ position: 'absolute', top: 45, left: 140, zIndex: 1 }}
          />
          <TriangleHeaderTiny
            style={{ position: 'absolute', top: 100, right: -100 }}
          />
        </DesktopFlex>
      </Flex>
    </Section>
  )
}

const DownloadContentHeader = props => {
  return (
    <>
      <Navbar {...props} />
      <Content {...props} />
      {props.clickedDownload ? (
        <PostDownload {...props} />
      ) : (
        <PreDownload {...props} />
      )}
    </>
  )
}

export default DownloadContentHeader
