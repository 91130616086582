import React, { useState } from 'react'
import styled from 'styled-components'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'
import { graphql } from 'gatsby'

import DownloadContentHeader from '../components/download_content/header'
import DownloadContentFooter from '../components/download_content/footer'
import Footer from '../components/footer'
import Head from '../components/head'
import Services from '../components/home/services'
import TriangleBottomDesktop from '../static/img/download_content/TriangleBottomDesktop.svg'
import TriangleBottomMobile from '../static/img/download_content/TriangleBottomMobile.svg'
import { DesktopFlex, MobileFlex } from '../components/breakpoints'

const StyledTriangleBottomDesktop = styled(TriangleBottomDesktop)`
  position: absolute;
  bottom: -70px;
  left: 20%;
  z-index: 1;
`
const StyledTriangleBottomMobile = styled(TriangleBottomMobile)`
  position: absolute;
  width: 100px;
  left: 20%;
  z-index: 1;
`

const DownloadContent = ({ data, pageContext }) => {
  const { wordpressPage: page } = data
  const {
    wordpressPage: {
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { download_url },
    },
  } = data

  const [clickedDownload, updateClick] = useState(false)
  const [validData, updateValidation] = useState(false)
  const handleDownloadClick = event => {
    if (typeof window !== undefined) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    window.open(download_url, '_blank')
    updateClick(!clickedDownload)
    event.preventDefault()
  }

  return (
    <BreakpointProvider>
      <Head
        alternates={pageContext.i18n}
        autopilot={pageContext.autopilot}
        description={yoast_wpseo_metadesc}
        lang={pageContext.lang}
        title={yoast_wpseo_title}
        uri={pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <DownloadContentHeader
          page={page}
          pageContext={pageContext}
          clickedDownload={clickedDownload}
          onClick={handleDownloadClick}
          validData={validData}
          updateValidation={updateValidation}
        />
        <Flex css={{ position: 'relative' }}>
          <DesktopFlex>
            <StyledTriangleBottomDesktop />
          </DesktopFlex>
          <MobileFlex>
            {clickedDownload ? (
              <StyledTriangleBottomMobile style={{ bottom: -40 }} />
            ) : (
              <StyledTriangleBottomMobile style={{ bottom: -15 }} />
            )}
          </MobileFlex>
        </Flex>
        {clickedDownload ? (
          <>
            <Services pageContext={pageContext} />
            <Footer pageContext={pageContext} />
          </>
        ) : (
          <DownloadContentFooter page={page} pageContext={pageContext} />
        )}
      </Flex>
    </BreakpointProvider>
  )
}
export default DownloadContent
export const pageQuery = graphql`
  query DownloadPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        title
        download_url
        post_download {
          thanks_title
          thanks_tagline {
            click
            text
          }
          whats_next_title
          whats_next_posts {
            post_id_1
            post_id_2
            post_id_3
            post_id_4
          }
        }
        pre_download {
          tagline
          description
          download_title
          selector_title
          selector_subtitle
          selector_yes
          selector_no
          form {
            first_name
            last_name
            email
            job_title
            company
            country
            selector {
              title
              subtitle
              choose_option
              options {
                option_1
                option_2
              }
            }
            i_agree
            privacy_policy
            button
          }
          bullet_points
          img {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
