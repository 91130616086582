import { getFieldValue } from "../../constants/forms"

export const DEFAULT_VALUE_TRAFFIC = 'No'
const SELECTABLE_FIELD_TRAFFIC = 'trafficVolume'
const SELECTABLE_FIELD_MAU = 'estMAU'

const emptyForm = {
  comesFrom: 'web',
  company: '',
  country: '',
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  GDPRcheckBox: false,
  [SELECTABLE_FIELD_MAU]: '',
  estMAUOptions: [],
  traffic: DEFAULT_VALUE_TRAFFIC,
  [SELECTABLE_FIELD_TRAFFIC]: '',
  trafficVolumeOptions: []
}

export const formatSegmentData = (data, locale, slug) => {
  const properties = Object.assign({}, data)
  properties['lang'] = locale
  properties['slug'] = slug
  if (data.traffic === DEFAULT_VALUE_TRAFFIC) {
    properties.estMAU = getFieldValue(data.estMAU)
    properties.trafficVolume = ''
  } else {
    properties.estMAU = ''
    properties.trafficVolume = getFieldValue(data.trafficVolume)
  }
  
  delete properties.trafficVolumeOptions
  delete properties.estMAUOptions

  return properties
}

export const isValidForm = fields => {
  const invalidValues = Object.entries(fields)
    .filter(([key, value]) => key !== SELECTABLE_FIELD_MAU && key !== SELECTABLE_FIELD_TRAFFIC)
    .some(([key, value]) => value === '')

  return !invalidValues && (fields[SELECTABLE_FIELD_MAU] !== '' || fields[SELECTABLE_FIELD_TRAFFIC] !== '')
}

export const getEmptyForm = (trafficVolumeOptions, mauOptions) => ({
  ...emptyForm,
  ...{
    trafficVolumeOptions: [
      { traffic_volume_1: trafficVolumeOptions.option_1 },
      { traffic_volume_2: trafficVolumeOptions.option_2 },
      { traffic_volume_3: trafficVolumeOptions.option_3 },
      { traffic_volume_4: trafficVolumeOptions.option_4 },
      { traffic_volume_5: trafficVolumeOptions.option_5 },
      { traffic_volume_6: trafficVolumeOptions.option_6 },
      { traffic_volume_7: trafficVolumeOptions.option_7 },
    ],
    estMAUOptions: [
      { est_mau_1: mauOptions.option_1 },
      { est_mau_2: mauOptions.option_2 },
      { est_mau_3: mauOptions.option_3 },
      { est_mau_4: mauOptions.option_4 },
      { est_mau_5: mauOptions.option_5 },
    ],
  }
})
