import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import { useDownloadContentData } from '../../hooks'
import Section from '../Section'
import BlogPostCard from '../blog/blogPostCard'

// SVGs
import PostTrianglesDesktop from '../../static/img/download_content/PostTrianglesDesktop.svg'
import PostRTriangleMobile from '../../static/img/download_content/PostRTriangleMobile.svg'
import PostLTriangleMobile from '../../static/img/download_content/PostLTriangleMobile.svg'

const formBgColor = '#f2f2f2'
const BgForm = styled.div`
  background-color: ${formBgColor};
`

const ThanksTitle = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #333333;
`

const ThanksTagline = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  text-decoration: none;
`

const WhatsNextTitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
`

const StyledImg = styled(Img)`
  width: 208px;
  height: 270px;
  align-self: center;
  @media (max-width: 512px) {
    width: 154px;
    height: 200px;
  }
`

const PostDownload = props => {
  let {
    page: {
      acf: { download_url, pre_download, post_download },
    },
  } = props

  let { posts } = useDownloadContentData(props.pageContext.lang)
  let img = null
  try {
    img = pre_download.img
  } catch (e) {}
  const { edges: blogPosts } = posts
  const getTranslation = post =>
    post.polylang_translations.find(
      tr => tr.polylang_current_lang == props.pageContext.lang
    )
  let { whats_next_posts } = post_download
  let postsLang = []
  blogPosts.map(({ node: post }, i) => {
    post.polylang_translations.map((p, i) => {
      Object.entries(whats_next_posts).filter(([k, id]) => {
        if (p.wordpress_id == id) {
          postsLang.push(post)
        }
      })
    })
  })

  const downloadFile = () => {
    window.open(download_url, '_blank')
  }
  const ThanksContainer = props => (
    <Flex pr={[0, 50]} flexDirection="column">
      <ThanksTitle style={{ marginBottom: 10 }}>{props.title}</ThanksTitle>
      <Flex>
        <ThanksTagline style={{ marginTop: 0 }}>
          <a
            onClick={() => downloadFile()}
            style={{ color: '#FF2B5E', cursor: 'pointer' }}
          >
            {props.tagline.click}&nbsp;
          </a>
        </ThanksTagline>
        <ThanksTagline style={{ marginTop: 0 }}>
          {props.tagline.text}
        </ThanksTagline>
      </Flex>
    </Flex>
  )

  const TrackingPixels = () => (
    <>
      {props.page.title ==
        'Best Practices for Opening WhatsApp and Other Messaging Channels' && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=1852764&conversionId=1085171&fmt=gif"
          />
        </noscript>
      )}
      {props.page.title == 'WhatsApp E-guide' && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=1852764&conversionId=1085171&fmt=gif"
          />
        </noscript>
      )}
    </>
  )

  return (
    <>
      <TrackingPixels />
      <Section
        px={[15, 15]}
        pt={[40, 50]}
        pb={[56, 123]}
        background={<BgForm style={{ height: '100%', zIndex: 1 }} />}
      >
        <Flex mb={[40, 30]} flexDirection={['column', 'row']}>
          <ThanksContainer
            title={post_download.thanks_title}
            tagline={post_download.thanks_tagline}
          />
          {img && <StyledImg fluid={img.localFile.childImageSharp.fluid} />}
          <MobileFlex>
            <PostLTriangleMobile
              style={{ position: 'absolute', top: '8%', left: 0, zIndex: -1 }}
            />
            <PostRTriangleMobile
              style={{ position: 'absolute', top: '15%', right: 0, zIndex: -1 }}
            />
          </MobileFlex>
          <DesktopFlex>
            <PostTrianglesDesktop
              style={{ position: 'absolute', right: 0, zIndex: -1 }}
            />
          </DesktopFlex>
        </Flex>
        <Flex pt={[0, 55]} flexDirection="column">
          {postsLang.length > 0 && (
            <>
              <WhatsNextTitle>{post_download.whats_next_title}</WhatsNextTitle>
              <Flex flexDirection={['column', 'row']}>
                {postsLang.map((post, id) => {
                  let p = getTranslation(post)
                  // TODO: Talk with Mark and Caroline to define what data is sent to Segment
                  return (
                    p && (
                      <BlogPostCard
                        key={post.id}
                        post={p}
                        lang={props.pageContext.lang}
                        width={1 / 4}
                      />
                    )
                  )
                })}
              </Flex>
            </>
          )}
        </Flex>
      </Section>
    </>
  )
}

export default PostDownload
