import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import WhiteLogo from '../../static/img/hubtype_logo_white.svg'
import { useFooterData } from '../../hooks/use-footer-data'

const FooterItem = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #b282ff;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  text-decoration: none;
`
const FooterContainer = styled(Flex)`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -150px;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    background-color: #6500cb;
    z-index: -10;
  }
`

const DownloadContentFooter = props => {
  let lang = props.pageContext.lang
  const {
    acf: {
      privacy_policy_text,
      privacy_policy_slug,
      cookie_policy_text,
      cookie_policy_slug,
      terms_text,
      terms_slug,
      all_rights_reserved,
    },
  } = useFooterData(lang)
  return (
    <FooterContainer
      mt={[-20, 0]}
      pt={[30, 90]}
      pb={20}
      css={{
        position: 'relative',
        background: '#6500cb',
      }}
      flexDirection="column"
      alignItems="center"
    >
      <Flex>
        <Flex mr={[20, 572]}>
          <a href="/">
            <WhiteLogo />
          </a>
        </Flex>
        <Flex flexDirection={['column', 'row']}>
          <Flex flexWrap="wrap">
            <FooterItem href={privacy_policy_slug}>
              {privacy_policy_text}
            </FooterItem>
            <FooterItem href={cookie_policy_slug}>
              {cookie_policy_text}
            </FooterItem>
            <FooterItem href={terms_slug}>{terms_text}</FooterItem>
          </Flex>
          <Flex>
            <FooterItem style={{ color: '#b282ff' }}>
              {all_rights_reserved}
            </FooterItem>
          </Flex>
        </Flex>
      </Flex>
    </FooterContainer>
  )
}

export default DownloadContentFooter
