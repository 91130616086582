import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Flex } from '@rebass/grid'

import { DEFAULT_VALUE_TRAFFIC, formatSegmentData, getEmptyForm, isValidForm } from '../../data/forms/download'
import { FormContainer, Selector, Text, RadioInput } from '../forms'
import { OutlineButton } from '../buttons'
import { useLandingData } from '../../hooks'

const Form = props => {
  /* 
    TODO: The way of retrievieng the form should be refactored.
          We are using two forms in the same way in WhatsappLanding / DownloadContent.
  */
  let {
    acf: {
      radio_2,
      traffic_volume_selector,
      est_mau_selector,
      choose_option,
    },
  } = useLandingData(props.pageContext.lang)
  let {
    page: {
      acf: { pre_download },
    },
  } = props
  traffic_volume_selector.title = pre_download.selector_yes
  est_mau_selector.title = pre_download.selector_no

  const lang = props.pageContext.lang
  const slug = props.pageContext.relativeUrl
  const { form } = pre_download
  const initialState = getEmptyForm(traffic_volume_selector, est_mau_selector)
  const [userData, updateData] = useState(initialState)

  const handleChange = name => event => {
    updateData({ ...userData, [name]: event.target.value })
  }

  const validateData = () => {
    let properties = formatSegmentData(userData, lang, slug)

    if (!isValidForm(properties)) {
      return false
    }

    if (typeof window !== 'undefined') {
      window.analytics.identify(properties)
      window.analytics.track('Download Form', properties)
      let {
        email: Email,
        firstName: FirstName,
        lastName: LastName,
        ...custom
      } = properties
      window.Autopilot.run('associate', {
        Email,
        FirstName,
        LastName,
        custom,
      })
    }
    updateData(initialState)
    return true
  }

  return (
    <FormContainer>
      <Text
        width={[1, 1 / 2]}
        onChange={handleChange('firstName')}
        label={`${form.first_name}`}
        value={userData.firstName}
      />
      <Text
        width={[1, 1 / 2]}
        onChange={handleChange('lastName')}
        label={`${form.last_name}`}
        value={userData.lastName}
      />
      <Text
        width={[1, 1 / 2]}
        onChange={handleChange('email')}
        label={`${form.email}`}
        value={userData.email}
        type="email"
        name="email"
        autoComplete="email"
      />
      <Text
        width={[1, 1 / 2]}
        onChange={handleChange('title')}
        label={`${form.job_title}`}
        value={userData.title}
      />
      <Text
        width={[1, 1 / 2]}
        onChange={handleChange('company')}
        label={`${form.company}`}
        value={userData.company}
      />
      <Text
        width={[1, 1 / 2]}
        onChange={handleChange('country')}
        label={`${form.country}`}
        value={userData.country}
      />
      <Flex width={[1, 1 / 2]} mt={10} mb={[10, 30]} flexDirection="column">
        <RadioInput
          style={{ marginLeft: -15, marginTop: 0, width: '90%' }}
          radio={radio_2}
          value={userData.traffic}
          onChange={handleChange('traffic')}
        />
        {userData.traffic !== DEFAULT_VALUE_TRAFFIC && (
          <Selector
            item={traffic_volume_selector}
            label={`${choose_option}`}
            value={userData.trafficVolume}
            onChange={handleChange('trafficVolume')}
            options={userData.trafficVolumeOptions}
            identifier={'traffic_volume'}
          />
        )}
        {userData.traffic === DEFAULT_VALUE_TRAFFIC && (
          <Selector
            item={est_mau_selector}
            label={`${choose_option}`}
            value={userData.estMAU}
            onChange={handleChange('estMAU')}
            options={userData.estMAUOptions}
            identifier={'est_mau'}
          />
        )}
        <Flex py={10}>
          <FormControlLabel
            control={
              <Checkbox
                checked={userData.GDPRcheckBox}
                onChange={props =>
                  updateData({
                    ...userData,
                    GDPRcheckBox: props.target.checked,
                  })
                }
              />
            }
            label={
              <label>
                {form.i_agree}
                <span> </span>
                <a href="/privacy">{form.privacy_policy}</a>
              </label>
            }
          />
        </Flex>
        <Flex mt={20} width={[2 / 3, 1 / 2]}>
          <OutlineButton
            color="white"
            borderColor="#FF2B5E"
            hoverBackground="#FF4B76"
            value={props.value}
            onClick={event => validateData() && props.onClick(event)}
            style={{
              background: '#FF2B5E',
              width: '100%',
            }}
          >
            {form.button}
          </OutlineButton>
        </Flex>
      </Flex>
    </FormContainer>
  )
}

export default Form
