import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import Form from './download_form'
// SVGs
import TrianglesBottom from '../../static/img/download_content/TrianglesBottom.svg'
import Triangle1FormMobile from '../../static/img/download_content/Triangle1FormMobile.svg'
import Triangle2FormMobile from '../../static/img/download_content/Triangle2FormMobile.svg'

const formBgColor = '#f2f2f2'
const BgForm = styled.div`
  background-color: ${formBgColor};
`

const Tagline = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
`

const Description = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
`

const DownloadTitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #333333;
  @media (max-width: 512px) {
    margin-bottom: 0px;
  }
`

const StyledImg = styled(Img)`
  width: 300px;
  height: 390px;
  @media (max-width: 512px) {
    width: 211px;
    height: 274px;
  }
`

const PreDownload = props => {
  let {
    page: {
      acf: { pre_download },
    },
  } = props

  let {
    tagline,
    description,
    download_title,
    bullet_points,
    img,
  } = pre_download

  return (
    <>
      <Section
        px={[15, 15]}
        pt={[40, 50]}
        pb={[56, 123]}
        background={<BgForm style={{ height: '100%', zIndex: 1 }} />}
      >
        <Flex flexDirection={['column', 'row']}>
          <Flex width={[1, 2 / 3]} flexDirection="column">
            <Flex width={[1, 0.85]} flexDirection="column">
              <Tagline>{tagline}</Tagline>
              <Description
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              <DesktopFlex>
                <DownloadTitle>{download_title}</DownloadTitle>
              </DesktopFlex>
            </Flex>
            <MobileFlex flexDirection="column">
              <Flex
                dangerouslySetInnerHTML={{
                  __html: bullet_points,
                }}
              />
              <Triangle1FormMobile
                style={{ position: 'absolute', top: '38%', right: 0 }}
              />
              <Triangle2FormMobile
                style={{ position: 'absolute', top: '48%', left: 0 }}
              />
              <DownloadTitle>{download_title}</DownloadTitle>
            </MobileFlex>
            <DesktopFlex>
              <Form {...props} />
            </DesktopFlex>
          </Flex>
          <Flex pt={[0, 100]} flexDirection="column" width={[1, 1 / 3]}>
            <DesktopFlex px={[0, 40]}>
              {img && <StyledImg fluid={img.localFile.childImageSharp.fluid} />}
            </DesktopFlex>
            <DesktopFlex
              px={[0, 35]}
              pt={40}
              dangerouslySetInnerHTML={{
                __html: bullet_points,
              }}
            />
            <MobileFlex py={40} justifyContent="center">
              {img && <StyledImg fluid={img.localFile.childImageSharp.fluid} />}
            </MobileFlex>
            <MobileFlex>
              <Form {...props} />
            </MobileFlex>
          </Flex>
        </Flex>
      </Section>
      <DesktopFlex css={{ position: 'relative', zIndex: 1 }}>
        <TrianglesBottom
          style={{ position: 'absolute', bottom: 100, left: '40%' }}
        />
      </DesktopFlex>
    </>
  )
}

export default PreDownload
